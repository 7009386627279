import {Global} from '@emotion/core';
import {config as fortawesomeConfig} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
// import 'fontsource-montserrat/latin-300.css';
// import 'fontsource-montserrat/latin-400.css';
// import 'fontsource-montserrat/latin-600.css';
// import 'fontsource-montserrat/latin-800.css';
// import 'fontsource-mukta/latin-400.css';
// import 'fontsource-mukta/latin-600.css';
// import 'fontsource-mukta/latin-800.css';
import {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {CookieBanner} from '../components/CookieBanner';
import {baseStyles} from '../styles/css';
import 'highlight.js/styles/atom-one-dark.css';

fortawesomeConfig.autoAddCss = false;

type Props = {
  children: React.ReactNode,
};

if (typeof window !== 'undefined') {
  const setCssWindowHeight = () => {
    console.log('asdasd')
    document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
  };

  setCssWindowHeight();

  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // Only for desktop.
    // I don't want the scrolling and the keyboard showing up to mess
    // with the page.
    window.addEventListener('resize', setCssWindowHeight);
  }

  window.addEventListener('orientationchange', () => {
    setTimeout(setCssWindowHeight, 100);
  });
}

export const BaseLayout = (props: Props) => {
  const {children} = props;

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
      </Helmet>

      <Global styles={baseStyles} />

      {children}

      <CookieBanner />
    </Fragment>
  );
};
