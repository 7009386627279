import {css} from '@emotion/core';
import {navigate} from 'gatsby';
import {useCallback} from 'react';
import {focusTransitionDuration} from '../../styles/animations';
import {commonBoxShadow} from '../../styles/css';
import {fontFamilyArrayToString, uiFontFamily} from '../../styles/fonts';
import {font60, font80, rhythm0_25, rhythm0_5, rhythm0_75, rhythm1, rhythm1_5} from '../../styles/typography';

export const buttonCss = css({
  ...font80,

  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  fontFamily: fontFamilyArrayToString(uiFontFamily),
  fontWeight: 600,
  textAlign: 'center',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderWidth: '2px',
  // border: 'none',
  borderRadius: rhythm1,
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  padding: `0 ${rhythm0_75}`,

  boxShadow: commonBoxShadow,

  transition: `
    background-color ${focusTransitionDuration}ms ease-out,
    border-color ${focusTransitionDuration}ms ease-out
  `,

  '&:hover': {
    // textDecoration: 'underline',
    // transform: 'scale(0.98)',
  },

  '&:focus': {
    outline: 'none',
    // transform: 'scale(0.98)',
    // textDecoration: 'underline',
  },

  '@media print': {
    colorAdjust: 'exact',
    WebkitPrintColorAdjust: 'exact',
  },
});

export const smallButtonCss = css({
  ...font60,

  lineHeight: rhythm0_5,
  letterSpacing: '0.25px',
  padding: `0 ${rhythm0_25}`,
});

export const bigButtonCss = css({
  lineHeight: rhythm1_5,
});

export const blockButtonCss = css({
  width: '100%',
});

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  link?: string,
  small?: true,
  big?: true,
  block?: true,
};

export const Button = (props: Props) => {
  const {children, type = 'button', link, onClick, small, big, block, ...htmlButtonProps} = props;

  // const {checkPageExistence, navigateToPage} = useContext(PagesContext);

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      } else if (link) {
        e.stopPropagation();
        e.preventDefault();

        navigate(link);
      }
    },
    [link, onClick],
  );

  return (
    <button type={type} css={[buttonCss, small && smallButtonCss, big && bigButtonCss, block && blockButtonCss]} onClick={handleClickButton} {...htmlButtonProps}>
      {children}
    </button>
  );
};
