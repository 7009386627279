import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {font100} from '../../styles/typography';
import {Button, Props} from './Button';

const linkButtonCss = css({
  ...font100,

  textTransform: 'none',
  textAlign: 'left',
  whiteSpace: 'normal',
  letterSpacing: '0px',
  border: 'none',
  background: 'none',
  appearance: 'none',
  margin: 0,
  padding: 0,

  color: colors.links.text,

  '&[disabled]': {
    color: colors.links.disabled.text,

    cursor: 'not-allowed',
  },
});

export const LinkButton = (props: Props) => (
  <Button css={linkButtonCss} {...props} />
);
