import {css} from '@emotion/core';
import {useCallback, useMemo} from 'react';
import {IntlConfig, IntlProvider} from 'react-intl';
import {Seo} from '../../components/Seo';
import {AllPagesContext} from '../../contexts/AllPagesContext';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';
import {ModalsProvider} from '../../contexts/ModalsProvider';

const pageMetaCss = css({
  display: 'none',
});

export const CmsPageBaseLayout = (props: any) => {
  const {data, pageContext, children} = props;

  const {
    posts,
    intl: intlNode,
    page: pageNode,
    pages: {nodes: pages},
    same: {nodes: same},
    prev: prevNode,
    next: nextNode,
    comments: {nodes: comments},
  } = data;

  const currentPageContext: CurrentPageContext = useMemo(
    () => ({
      data,
      type: pageNode.data.type,
      page: pageNode.data.page,
      path: pageNode.data.path,
      createdAt: pageNode.data.createdAt ? new Date(pageNode.data.createdAt) : undefined,
      updatedAt: pageNode.data.updatedAt ? new Date(pageNode.data.updatedAt) : undefined,
      title: pageNode.data.title,
      same: same.map((node: any) => ({
        path: node.data.path,
        locale: node.data.locale,
      })),
      prev: prevNode?.data,
      next: nextNode?.data,
      breadcrumbs: pageNode.data.breadcrumbs,
      headings: pageNode.headings,
      featuredImage: pageNode.data.featuredImage?.childImageSharp,
      posts: posts?.nodes.map((node: any) => ({
        ...node.data,
        excerpt: node.data.excerpt || node.excerpt,
        createdAt: new Date(node.data.createdAt),
        updatedAt: new Date(node.data.updatedAt),
        thumbnail: node.data.thumbnail?.childImageSharp,
      })),
      commentsEnabled: pageNode.data.commentsEnabled,
      commentsForm: pageNode.data.commentsForm,
      comments: comments.map((node: any) => ({
        ...node.data,
        createdAt: new Date(node.data.createdAt),
      })),
      track: pageNode.data.track && {
        gpx: pageNode.data.track.gpx.publicURL,
        kml: pageNode.data.track.kml.publicURL,
        geojson: JSON.parse(pageNode.data.track.geojson.contents.utf8),
        map: pageNode.data.track.map.childImageSharp,
        bicycles: pageNode.data.track.bicycles,
        duration: pageNode.data.track.duration,
        links: pageNode.data.track.links,
      },
    }),
    [pageNode, pageContext, same, posts],
  );

  const allPagesContext: AllPagesContext = useMemo(
    () => ({
      pages: pages.map((node: any) => node.data.page),
      paths: pages.map((node: any) => node.data.path),
      pagesByPage: pages.reduce(
        (res: AllPagesContext['pagesByPage'], node: any) => ({
          ...res,
          [node.data.page]: node.data,
        }),
        {},
      ),
      pagesByPath: pages.reduce(
        (res: AllPagesContext['pagesByPath'], node: any) => ({
          ...res,
          [node.data.path]: node.data,
        }),
        {},
      ),
      findPageByPage: (page) => {
        const node = pages.find((it: any) => it.data.page === page);

        if (node) {
          return {
            path: node.data.path,
            title: node.data.title,
          };
        }

        return undefined;
      },
      findPageByPath: (path) => {
        const node = pages.find((it: any) => it.data.path === path);

        if (node) {
          return {
            path: node.data.path,
            title: node.data.title,
          };
        }

        return undefined;
      },
    }),
    [pages],
  );

  const onIntlError: IntlConfig['onError'] = useCallback(
    (err) => { console.debug(err); }, // TODO: report error somewhere
    [],
  );

  const pageMeta = useMemo(
    () => ({__html: `<!--${JSON.stringify({
      indexable: pageNode.data.indexable,
    })}-->`}),
    [pageNode],
  );

  return (
    <IntlProvider locale={pageContext.locale} defaultLocale="en" messages={intlNode.messages} onError={onIntlError}>
      <AllPagesContext.Provider value={allPagesContext}>
        <CurrentPageContext.Provider value={currentPageContext}>
          <ModalsProvider>
            <Seo />

            {children}

            <div css={pageMetaCss} id="___page-meta" dangerouslySetInnerHTML={pageMeta} />
          </ModalsProvider>
        </CurrentPageContext.Provider>
      </AllPagesContext.Provider>
    </IntlProvider>
  );
};
