const Color = require('color');

const bodyText = '#000000';
const lightText = '#5a5a5a';
const headers = bodyText;
const link = '#944aa0';
// const primary = '#2780E3';
// const danger = '#FF0039';

const defaultButton = {
  text: '#fff',
  background: '#000000',
  border: '#000000',
};

const primaryButton = {
  text: '#fff',
  background: link,
  border: link,
};

const dangerButton = {
  text: '#fff',
  background: '#d90030',
  border: '#d90030',
};

const warningButton = {
  text: '#fff',
  background: '#FF7518',
  border: '#FF7518',
};

const successButton = {
  text: '#fff',
  background: '#3FB618',
  border: '#3FB618',
};

const donationAmountButton = {
  text: '#fff',
  background: '#373a3c',
  border: '#373a3c',
};

exports.colors = {
  headers,

  links: {
    text: link,
    icon: Color(bodyText).darken(0.3).string(),
    // background: Color(link).alpha(0).string(),

    active: {
      text: '#ffffff',
      background: link,
    },

    hover: {
      text: '#ffffff',
      background: link,
    },
    // active: Color(link).darken(0.4).string(),

    disabled: {
      text: Color(link).desaturate(0.8).string(),
    },
  },

  header: {
    border: '#cccccc',
  },

  body: {
    lightText,
    text: bodyText,
    background: '#ffffff',
  },

  warning: {
    text: '#FF7518',
  },

  footer: {
    background: 'rgba(0, 0, 0, .5)',
  },

  map: {
    border: '#cccccc',
    text: bodyText,
  },

  track: {
    map: {
      path: '#666666',
    },
    elevationProfile: {
      path: '#666666',
      area: '#cccccc',
      guide: '#999999',
      text: lightText,
    },
  },

  borders: {
    common: '#cccccc',
  },

  backgrounds: {
    light: '#eeeeee',
    dark: '#333333',
  },

  cookieBanner: {
    background: 'rgba(0, 0, 0, .75)',
    text: '#ffffff',
  },

  overlay: {
    background: Color('#000000').alpha(0.82).toString(),
  },

  modals: {
    modal: {
      background: '#ffffff',
      text: Color('#42485d').darken(0.5).string(),
      // shadow: '#fff',
    },
    notifications: {
      success: {
        text: '#fff',
        background: '#3FB618',
      },
    },
  },

  shadow: 'rgba(0, 0 , 0, .2)',

  // map: {
  //   border: '#42485d',
  //   text: bodyText,
  // },

  inputs: {
    border: 'rgba(0, 0, 0, 0.1)',
    background: '#fff',
    text: bodyText,

    focus: {
      border: link,
      // background: '#efefef',
    },

    invalid: {
      // shadow: '#FF0039',
      border: '#ff5d00',
    },
  },

  labels: {
    invalid: {
      text: '#ffffff',
      background: '#ff5d00',
    },
  },

  alerts: {
    info: {
      text: '#502c61',
      link: '#331c3e',
      background: '#ebddf1',
      border: '#ebddf1',
    },
    success: {
      text: '#ffffff',
      // link: '#113206',
      background: '#000000',
      // border: '#d9f0d1',
    },
    warning: {
      text: '#ffffff',
      // link: '#562808',
      background: '#ff5d00',
      border: '#ff5d00',
    },
    danger: {
      text: '#ffffff',
      // link: '#520012',
      background: '#ff5d00',
      // border: '#ffccd7',
    },
  },

  buttons: {
    default: {
      ...defaultButton,

      focus: {
        background: '#333',
        border: '#333',
      },

      active: {
        background: '#333',
        border: '#333',
        // border: Color(link).darken(0.2).string(),
      },
    },

    primary: {
      ...primaryButton,

      focus: {
        background: Color(primaryButton.background).darken(0.2).string(),
        border: Color(primaryButton.border).darken(0.2).string(),
      },

      active: {
        background: Color(primaryButton.background).darken(0.2).string(),
        border: Color(primaryButton.background).darken(0.2).string(),
        // border: Color(link).darken(0.2).string(),
      },

      disabled: {
        background: Color(primaryButton.background).desaturate(0.8).string(),
        border: Color(primaryButton.background).desaturate(0.8).string(),
      },
    },

    danger: {
      ...dangerButton,

      focus: {
        background: Color(dangerButton.background).lighten(0.2).string(),
        border: Color(dangerButton.border).lighten(0.2).string(),
      },

      active: {
        background: Color(dangerButton.background).darken(0.2).string(),
        border: Color(dangerButton.background).darken(0.2).string(),
        // border: Color(link).darken(0.2).string(),
      },

      disabled: {
        background: Color(dangerButton.background).desaturate(0.8).string(),
        border: Color(dangerButton.background).desaturate(0.8).string(),
      },
    },

    warning: {
      ...warningButton,

      focus: {
        background: Color(warningButton.background).lighten(0.2).string(),
        border: Color(warningButton.border).lighten(0.2).string(),
      },

      active: {
        background: Color(warningButton.background).darken(0.2).string(),
        border: Color(warningButton.background).darken(0.2).string(),
        // border: Color(link).darken(0.2).string(),
      },

      disabled: {
        background: Color(warningButton.background).desaturate(0.8).string(),
        border: Color(warningButton.background).desaturate(0.8).string(),
      },
    },

    success: {
      ...successButton,

      focus: {
        background: Color(successButton.background).lighten(0.2).string(),
        border: Color(successButton.border).lighten(0.2).string(),
      },

      active: {
        background: Color(successButton.background).darken(0.2).string(),
        border: Color(successButton.background).darken(0.2).string(),
        // border: Color(link).darken(0.2).string(),
      },

      disabled: {
        background: Color(successButton.background).desaturate(0.8).string(),
        border: Color(successButton.background).desaturate(0.8).string(),
      },
    },
  },

  donation: {
    banner: {
      background: 'yellow',

      buttons: {
        amount: {
          ...donationAmountButton,

          focus: {
            background: Color(donationAmountButton.background).lighten(0.2).string(),
            border: Color(donationAmountButton.border).lighten(0.2).string(),
          },

          active: {
            background: Color(donationAmountButton.background).darken(0.2).string(),
            border: Color(donationAmountButton.background).darken(0.2).string(),
            // border: Color(link).darken(0.2).string(),
          },
        },
      },
    },
  },
};
