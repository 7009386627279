import {graphql, useStaticQuery} from 'gatsby';
import {useContext, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {AllPagesContext} from '../../contexts/AllPagesContext';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';

if (!process.env.SITE_URL) {
  throw new Error(`${__filename} Missing process.env.SITE_URL`);
}

const mapLanguages = (languages: string[]) => languages
  .map((language) => {
    const schema = {
      '@type': 'Language',
      alternateName: language,
    };

    if (language === 'it') {
      return {...schema, name: 'Italian'};
    }

    if (language === 'en') {
      return {...schema, name: 'English'};
    }

    return schema;
  });

export const LdJson = () => {
  const {
    business, person,
    site: {image: {childImageSharp: {resize: {src: siteImagePath}}}, ...site},
  } = useStaticQuery(query);

  const {page, path, title, breadcrumbs, featuredImage, type} = useContext(CurrentPageContext);

  const {locale} = useIntl();

  const {findPageByPage} = useContext(AllPagesContext);

  // TODO: implement https://smokielee.com/blog/2017/08/07/what-is-structured-data-schema-org/

  const personSchema = useMemo(
    () => ({
      '@type': 'Person',
      '@id': `${process.env.SITE_URL!}#person`,
      name: `${person.firstName} ${person.lastName}`,

      familyName: person.lastName,
      givenName: person.firstName,
      gender: person.gender,
      jobTitle: person.jobTitle,
      knowsLanguage: mapLanguages(person.knownLanguages),
    }),
    [person],
  );

  const organizationSchema = useMemo(
    () => ({
      '@type': 'Organization',
      '@id': `${process.env.SITE_URL!}#organization`,
      name: business.name,
      url: process.env.SITE_URL!,

      legalName: business.name,
      taxID: business.taxId,
      vatID: business.vatId,
      address: {
        '@type': 'PostalAddress',
        streetAddress: business.address,
        addressLocality: business.city,
        addressRegion: business.state,
        postalCode: business.postalCode,
        addressCountry: business.country,
      },
      knowsLanguage: mapLanguages(business.knownLanguages),
    }),
    [business],
  );

  const websiteSchema = useMemo(
    () => {
      const indexPath = findPageByPage('index')!.path;
      const searchPath = findPageByPage('search')?.path;

      return {
        '@type': 'WebSite',
        '@id': `${process.env.SITE_URL!}${indexPath}#website`,
        name: site.title,
        url: `${process.env.SITE_URL!}${indexPath}`,
        image: `${process.env.SITE_URL!}${siteImagePath}`,

        inLanguage: locale,
        headline: site.title,

        copyrightYear: new Date().getFullYear(),
        copyrightHolder: {
          '@id': organizationSchema['@id'],
        },

        about: {
          '@id': organizationSchema['@id'],
        },
        accountablePerson: {
          '@id': personSchema['@id'],
        },
        author: {
          '@id': organizationSchema['@id'],
        },
        creator: {
          '@id': organizationSchema['@id'],
        },
        potentialAction: searchPath
          ? {
            '@type': 'SearchAction',
            target: `${searchPath}?q={q}`,
            'query-input': 'required maxlength=100 name=q',
          }
          : undefined,
      };
    },
    [findPageByPage, locale, site, organizationSchema, personSchema, siteImagePath],
  );

  const breadcrumbSchema = useMemo(
    () => {
      const last = breadcrumbs?.find((it) => !it.path);

      const parents = (breadcrumbs || [])
        .filter((it) => !!it.path)
        .map((breadcrumb, index) => {
          const page = findPageByPage(breadcrumb.path!);

          return {
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.label,
            item: `${process.env.SITE_URL}${page ? page.path : breadcrumb.path}`,
          };
        });

      return {
        '@type': 'BreadcrumbList',
        '@id': `${process.env.SITE_URL!}${path}#breadcrumbs`,
        itemListElement: [
          ...parents,
          {
            '@type': 'ListItem',
            position: parents.length + 1,
            name: last?.label || title,
          },
        ],
      };
    },
    [title, breadcrumbs],
  );

  const pageSchema = useMemo(
    () => {
      const schema: any = {
        '@id': `${process.env.SITE_URL!}${path}#page`,
        name: title,
        url: `${process.env.SITE_URL!}${path}`,

        inLanguage: locale,
        headline: site.title,

        copyrightYear: new Date().getFullYear(),
        copyrightHolder: {
          '@id': organizationSchema['@id'],
        },

        isPartOf: {
          '@id': websiteSchema['@id'],
        },

        accountablePerson: {
          '@id': personSchema['@id'],
        },
        author: {
          '@id': organizationSchema['@id'],
        },
        creator: {
          '@id': organizationSchema['@id'],
        },

        breadcrumb: breadcrumbSchema,
      };

      if (type === 'blog-post') {
        schema['@type'] = 'BlogPosting';
      } else if (type === 'blog-index') {
        schema['@type'] = 'Blog';
      } else if (page === 'contact') {
        schema['@type'] = 'ContactPage';
      } else {
        schema['@type'] = 'WebPage';
      }

      if (page === 'index') {
        schema.image = `${process.env.SITE_URL!}${siteImagePath}`;

        schema.about = {
          '@id': websiteSchema['@id'],
        };
      } else if (featuredImage) {
        schema.image = `${process.env.SITE_URL!}${featuredImage.seo.src}`;
      }

      return schema;
    },
    [findPageByPage, breadcrumbSchema, page, type, path, locale, title, site, websiteSchema, organizationSchema, personSchema, siteImagePath, featuredImage],
  );

  const jsonLd = useMemo(
    () => JSON.stringify(
      {'@context': 'https://schema.org', '@graph': [
        personSchema,
        organizationSchema,
        websiteSchema,
        pageSchema,
      ]},
      null,
      '  ',
    ),
    [personSchema, organizationSchema, websiteSchema, pageSchema],
  );

  return (
    <Helmet>
      <script type="application/ld+json">{jsonLd}</script>
    </Helmet>
  );
};

const query = graphql`
  query {
    site: settingsYaml(settings: {eq: "site"}) {
      title
      image {
        childImageSharp {
          resize(width: 1200) {
            src
          }
        }
      }
    }

    business: settingsYaml(settings: {eq: "business"}) {
      name
      taxId
      vatId
      address
      city
      state
      postalCode
      country
      knownLanguages
    }

    person: settingsYaml(settings: {eq: "person"}) {
      firstName
      lastName
      gender
      jobTitle
      knownLanguages
    }
  }
`;
