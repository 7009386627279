import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {Button, Props} from './Button';

const dangerButtonCss = css({
  color: colors.buttons.danger.text,
  borderColor: colors.buttons.danger.border,
  backgroundColor: colors.buttons.danger.background,

  '&:hover': {
    backgroundColor: colors.buttons.danger.focus.background,
    borderColor: colors.buttons.danger.focus.border,
  },

  '&:focus': {
    backgroundColor: colors.buttons.danger.focus.background,
    borderColor: colors.buttons.danger.focus.border,
  },

  '&:active': {
    backgroundColor: colors.buttons.danger.active.background,
    borderColor: colors.buttons.danger.active.border,
  },

  '&[disabled]': {
    backgroundColor: colors.buttons.danger.disabled.background,
    borderColor: colors.buttons.danger.disabled.border,

    cursor: 'not-allowed',
  },
});

export const DangerButton = (props: Props) => (
  <Button css={dangerButtonCss} {...props} />
);
