import {css} from '@emotion/core';
import {rhythm0_25, rhythm0_5} from '../../styles/typography';

const mainContentCss = css({
  textAlign: 'left',

  '& h1': {
    fontWeight: 800,
    // textTransform: 'uppercase',
    // textAlign: 'center',

    '& a': {
      fontWeight: 'inherit',
    },
  },

  '& h2': {
    fontWeight: 800,
    textTransform: 'uppercase',
    // textAlign: 'justify',

    '& a': {
      fontWeight: 'inherit',
    },
  },

  '& h3': {
    fontWeight: 800,
    textTransform: 'uppercase',
    // textAlign: 'justify',

    '& a': {
      fontWeight: 'inherit',
    },
  },

  '& h4': {
    fontWeight: 800,
    textTransform: 'uppercase',
    // textAlign: 'justify',

    '& a': {
      fontWeight: 'inherit',
    },
  },

  '& h5': {
    fontWeight: 800,
    textTransform: 'uppercase',
    // textAlign: 'justify',

    '& a': {
      fontWeight: 'inherit',
    },
  },

  // '& p': {
  //   textAlign: 'justify',
  // },

  '& li': {
    marginBottom: rhythm0_25,
    // textAlign: 'justify',
  },

  '& p + ul': {
    marginTop: `-${rhythm0_5}`,
  },
});

export type Props = {
  children: React.ReactNode,
  className?: string,
};

export const MainContent = (props: Props) => {
  const {children, className} = props;

  return (
    <div css={[mainContentCss]} className={className}>
      {children}
    </div>
  );
};
