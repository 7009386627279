import { ClassNames, css, Global, GlobalProps } from '@emotion/core';
import {
  faFacebook,
  faGithub,
  faGitlab,
  faLinkedin,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faEnvelope,
  faMapMarker,
  faPhone,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  MIN_DEFAULT_MEDIA_QUERY,
  MIN_TABLET_MEDIA_QUERY,
} from 'typography-breakpoint-constants';
import defaultLocale from '../../../i18n/default-locale.json';
import { AllPagesContext } from '../../contexts/AllPagesContext';
import { CurrentPageContext } from '../../contexts/CurrentPageContext';
// import {AuthenticationContext} from '../../contexts/AuthenticationContext';
import { animationDuration, modalAnimatedCss } from '../../styles/animations';
import { colors } from '../../styles/colors';
import { commonBoxShadow, linkCss } from '../../styles/css';
import { fontFamilyArrayToString, uiFontFamily } from '../../styles/fonts';
import {
  font60,
  font80,
  rhythm0_125,
  rhythm0_25,
  rhythm0_375,
  rhythm0_5,
  rhythm1_25,
  rhythm2,
} from '../../styles/typography';
import { trackGtagEvent } from '../../utils/tracking/trackGtag';
import { Link } from '../Link';
import { Overlay } from '../Overlay';
import { SafeText } from '../Safe';

const messages = defineMessages({
  businessAddress: {
    id: 'components.MainMenu.businessAddress',
    defaultMessage: `{address}, {postalCode} {city} ({state}) {
    country, select,
      it { Italy}
  }`,
  },
  businessVatId: {
    id: 'components.MainMenu.businessVatId',
    defaultMessage: 'P.IVA: {vatId}',
  },
  businessTaxId: {
    id: 'components.MainMenu.businessTaxId',
    defaultMessage: 'C.F.: {taxId}',
  },
  facebook: { id: 'components.MainMenu.facebook', defaultMessage: 'Facebook' },
  linkedin: { id: 'components.MainMenu.linkedin', defaultMessage: 'LinkedIn' },
  whatsapp: { id: 'components.MainMenu.whatsapp', defaultMessage: 'WhatsApp' },
  telegram: { id: 'components.MainMenu.telegram', defaultMessage: 'Telegram' },
  github: { id: 'components.MainMenu.github', defaultMessage: 'GitHub' },
  gitlab: { id: 'components.MainMenu.gitlab', defaultMessage: 'GitLab' },
  search: { id: 'components.MainMenu.search', defaultMessage: 'Search' },
  menu: { id: 'components.MainMenu.menu', defaultMessage: 'Menu' },
});

const mainMenuCss = css({
  // overflow: 'auto',
  // marginRight: `-${typography.rhythm(0.5)}`,

  [MIN_DEFAULT_MEDIA_QUERY]: {
    marginRight: `-${rhythm0_25}`,
  },
});

const menuCss = css({
  zIndex: 100,
  position: 'fixed',
  top: 0,
  width: '100vw',
  left: '100vw',
  opacity: 0,
  height: ['100vh', 'var(--window-height, 100vh)'],
  background: 'white',
  boxShadow: commonBoxShadow,
  textAlign: 'center',
  transition: `
    opacity ${animationDuration}ms ease,
    left ${animationDuration}ms ease
  `,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  color: colors.body.text,
  paddingBottom: rhythm0_5,

  [MIN_TABLET_MEDIA_QUERY]: {
    width: '75vw',
  },

  [MIN_DEFAULT_MEDIA_QUERY]: {
    marginLeft: rhythm0_25,
    paddingBottom: 0,
    color: 'inherit',
    position: 'static',
    display: 'inline',
    // verticalAlign: 'baseline',
    height: 'auto',
    background: 'none',
    boxShadow: 'none',
    opacity: 1,
    width: 'auto',
    padding: 0,

    '& hr': {
      display: 'none',
    },
  },

  '& hr': {
    margin: `${rhythm0_25} ${rhythm0_5}`,
    background: 'none',
    border: 'none',
  },

  [`
    & a,
    & button
  `]: {
    textDecoration: 'none',
  },
});

const menuContentCss = css({
  overflow: 'auto',
  overscrollBehavior: 'contain',

  [MIN_DEFAULT_MEDIA_QUERY]: {
    padding: 0,
    overflow: 'hidden',
    display: 'inline',
  },
});

const openMenuCss = css({
  left: 0,
  opacity: 1,

  [MIN_TABLET_MEDIA_QUERY]: {
    width: '75vw',
    left: '25vw',
  },

  [MIN_DEFAULT_MEDIA_QUERY]: {
    width: 'auto',
  },
});

const overlayCss = css({
  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const mainMenuLinkCss = css(linkCss, {
  ...font80,
  lineHeight: rhythm1_25,
  fontWeight: 300,
  display: 'block',
  color: 'inherit',
});

const mainMenuItemCss = css(linkCss, {
  ...font80,
  lineHeight: rhythm1_25,

  fontFamily: fontFamilyArrayToString(uiFontFamily),
  fontWeight: 400,
  // textTransform: 'uppercase',
  display: 'block',
  color: 'inherit',

  [MIN_DEFAULT_MEDIA_QUERY]: {
    ...font80,

    fontWeight: 600,
    display: 'inline-block',
    lineHeight: rhythm2,
    padding: `0 ${rhythm0_375}`,

    // 'hr + &': {
    //   marginLeft: rhythm0_25,
    // },
  },
});

const closeButtonCss = css(linkCss, {
  background: 'none',
  appearance: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  color: 'inherit',
  padding: `${rhythm0_5} 0`,

  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const hamburgerCss = css(linkCss, {
  fontWeight: 600,
  fontFamily: fontFamilyArrayToString(uiFontFamily),
  // textTransform: 'uppercase',
  margin: 0,
  background: 'none',
  appearance: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  color: 'inherit',
  // padding: `0 ${typography.rhythm(0.5)}`,
  padding: 0,

  ...font80,
  lineHeight: rhythm2,

  // [MIN_MOBILE_MEDIA_QUERY]: {
  //   ...typography.adjustFontSizeTo('100%'),
  // },

  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const mobileCss = css({
  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const footerCss = css({
  ...font60,
  fontWeight: 300,

  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const localeLinksCss = css({
  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'inline',
    marginRight: rhythm0_25,
  },
});

const localeLinkCss = css(linkCss, {
  ...font80,
  color: 'inherit',
  // textTransform: 'uppercase',
  fontWeight: 400,
  display: 'inline-block',
  // verticalAlign: 'baseline',
  lineHeight: rhythm2,
  fontFamily: fontFamilyArrayToString(uiFontFamily),

  padding: `0 ${rhythm0_25}`,

  [MIN_DEFAULT_MEDIA_QUERY]: {
    ...font80,
    fontWeight: 600,
    // color: colors.body.lightText,
    lineHeight: rhythm2,
    padding: `0 ${rhythm0_125}`,
  },
});

const localeIconCss = css({
  [MIN_DEFAULT_MEDIA_QUERY]: {
    display: 'none',
  },
});

const globalStyles: GlobalProps<unknown>['styles'] = {
  [`
    .main-menu-hamburger,
    .main-menu-locale-link
  `]: {
    textShadow: 'none',
  },
};

export const MainMenu = () => {
  const {
    site,
    business,
    social,
    mainMenus: { nodes: mainMenus },
  } = useStaticQuery(query);

  const { locale } = useIntl();

  const { links } = mainMenus.find((menu: any) => menu.locale === locale);

  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpen((prev) => {
      if (prev && !location.state?.mainMenuOpen) {
        return false;
      }

      if (!prev && location.state?.mainMenuOpen) {
        return true;
      }

      return prev;
    });
  }, [location]);

  const open = useCallback(() => {
    trackGtagEvent('main_menu_opened');

    setIsOpen(true);

    const redirect = `${location.pathname}${location.search}${location.hash}`;

    navigate(redirect, {
      state: { mainMenuOpen: true, disableScrollUpdate: true },
    });
  }, [location]);

  const close = useCallback(() => {
    setIsOpen(false);

    if (location.state?.mainMenuOpen) {
      navigate(-1);
    }
  }, [location]);

  const toggle = useCallback(
    (e: React.MouseEvent) => {
      if (isOpen) {
        close();
      } else {
        open();
      }
    },
    [open, close, isOpen],
  );

  useEffect(() => {
    const keyupHandler = (e: KeyboardEvent) => {
      if (e.which === 27) {
        close();
      }
    };

    document.addEventListener('keyup', keyupHandler);

    return () => {
      document.removeEventListener('keyup', keyupHandler);
    };
  }, [close]);

  const { findPageByPage } = useContext(AllPagesContext);

  const { same } = useContext(CurrentPageContext);

  const replace = useMemo(() => !!location.state?.mainMenuOpen, [location]);

  return (
    <div css={mainMenuCss} className="main-menu">
      <Global styles={globalStyles} />

      <button
        type="button"
        css={hamburgerCss}
        onClick={toggle}
        className="main-menu-hamburger"
      >
        <FormattedMessage {...messages.menu} />{' '}
        <FontAwesomeIcon icon={faBars} fixedWidth />
      </button>

      <ClassNames>
        {({ css }) => (
          <TransitionGroup component={null}>
            {isOpen && (
              <CSSTransition
                timeout={animationDuration}
                classNames={modalAnimatedCss(css)}
              >
                <Overlay css={overlayCss} onClick={close} />
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </ClassNames>

      <nav css={[menuCss, isOpen && openMenuCss]}>
        <button type="button" css={closeButtonCss} onClick={close}>
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </button>

        <div css={menuContentCss}>
          {same.length > 1 && (
            <div css={localeLinksCss}>
              {same
                .sort((a, b) => {
                  if (a.locale === defaultLocale) {
                    return -1;
                  }

                  if (b.locale === defaultLocale) {
                    return 1;
                  }

                  if (a.locale < b.locale) {
                    return -1;
                  }

                  if (a.locale > b.locale) {
                    return 1;
                  }

                  return 0;
                })
                .map((page) => (
                  <Link
                    key={page.locale}
                    css={localeLinkCss}
                    href={page.path}
                    hrefLang={page.locale}
                    className="main-menu-locale-link"
                  >
                    <span css={localeIconCss}>
                      {page.locale === 'it' && '🇮🇹'}
                      {page.locale === 'en' && '🇬🇧'}{' '}
                    </span>
                    {page.locale}
                  </Link>
                ))}
            </div>
          )}

          {links.map((link: any, index: number) => {
            const { label, path } = link;

            return (
              <Fragment key={`path-${path}`}>
                <Link replace={replace} href={path} css={mainMenuItemCss}>
                  {label}
                </Link>
              </Fragment>
            );
          })}

          <hr />

          <Link replace={replace} css={mainMenuItemCss} href="search">
            <FontAwesomeIcon icon={faSearch} fixedWidth />

            <span css={mobileCss}>
              {' '}
              <FormattedMessage {...messages.search} />
            </span>
          </Link>

          <hr />

          <SafeText text={business.phoneSafe}>
            {(text) =>
              text && (
                <Link
                  replace={replace}
                  css={[mainMenuLinkCss, mobileCss]}
                  href={`tel:${text}`}
                >
                  <FontAwesomeIcon icon={faPhone} fixedWidth /> {text}
                </Link>
              )
            }
          </SafeText>

          <SafeText text={business.emailSafe}>
            {(text) =>
              text && (
                <Link
                  replace={replace}
                  css={[mainMenuLinkCss, mobileCss]}
                  href={`mailto:${text}`}
                >
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth /> {text}
                </Link>
              )
            }
          </SafeText>

          {/* <Link replace={replace} css={[mainMenuLinkCss, mobileCss]} href={`geo:${[business.geoPosition.lat, business.geoPosition.lon]}?q=${[business.geoPosition.lat, business.geoPosition.lon]}`}>
            <FontAwesomeIcon icon={faMapMarker} fixedWidth />
            {' '}
            <FormattedMessage {...messages.businessAddress} values={business} />
          </Link> */}

          <hr />

          {Object.entries<string>(social.links).map(([provider, url]) => {
            if (provider === 'facebook') {
              return (
                <Link
                  replace={replace}
                  css={[mainMenuItemCss, mobileCss]}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faFacebook} fixedWidth />{' '}
                  <FormattedMessage {...messages.facebook} />
                </Link>
              );
            }

            if (provider === 'linkedin') {
              let linkedUrl = url;

              if (locale === 'it') {
                linkedUrl += '?locale=it_IT';
              }

              return (
                <Link
                  replace={replace}
                  css={[mainMenuItemCss, mobileCss]}
                  key={provider}
                  href={linkedUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faLinkedin} fixedWidth />{' '}
                  <FormattedMessage {...messages.linkedin} />
                </Link>
              );
            }

            if (provider === 'whatsappSafe') {
              return (
                <SafeText key={provider} text={url}>
                  {(text) =>
                    text && (
                      <Link
                        replace={replace}
                        css={[mainMenuItemCss, mobileCss]}
                        href={text}
                        target="_blank"
                        rel="noopener"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} fixedWidth />{' '}
                        <FormattedMessage {...messages.whatsapp} />
                      </Link>
                    )
                  }
                </SafeText>
              );
            }

            if (provider === 'telegram') {
              return (
                <Link
                  replace={replace}
                  css={[mainMenuItemCss, mobileCss]}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faTelegram} fixedWidth />{' '}
                  <FormattedMessage {...messages.telegram} />
                </Link>
              );
            }

            if (provider === 'github') {
              return (
                <Link
                  replace={replace}
                  css={[mainMenuItemCss, mobileCss]}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faGithub} fixedWidth />{' '}
                  <FormattedMessage {...messages.github} />
                </Link>
              );
            }

            if (provider === 'gitlab') {
              return (
                <Link
                  replace={replace}
                  css={[mainMenuItemCss, mobileCss]}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faGitlab} fixedWidth />{' '}
                  <FormattedMessage {...messages.gitlab} />
                </Link>
              );
            }

            return null;
          })}

          <hr />

          <Link
            replace={replace}
            href="privacy-policy"
            css={[mainMenuItemCss, mobileCss]}
          >
            {findPageByPage('privacy-policy')!.title}
          </Link>

          <hr />

          <div css={[footerCss, mobileCss]}>
            {/* <FormattedMessage {...messages.businessVatId} values={business} />

            <br />

            <FormattedMessage {...messages.businessTaxId} values={business} />

            <br />
            <br /> */}

            {site.copyrightHolder}
            {' © '}
            {new Date().getFullYear()}
          </div>
        </div>
      </nav>
    </div>
  );
};

MainMenu.whyDidYouRender = true;

const query = graphql`
  query {
    site: settingsYaml(settings: { eq: "site" }) {
      title
      copyrightHolder
    }

    business: settingsYaml(settings: { eq: "business" }) {
      taxId
      vatId
      address
      city
      state
      postalCode
      country
      phoneSafe
      emailSafe

      geoPosition {
        lon
        lat
      }
    }

    social: settingsYaml(settings: { eq: "social" }) {
      links {
        facebook
        linkedin
        github
        gitlab
        whatsappSafe
        telegram
      }
    }

    mainMenus: allNavigationYaml(filter: { navigation: { eq: "main-menu" } }) {
      nodes {
        locale

        links {
          path
          label
        }
      }
    }
  }
`;
