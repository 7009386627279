import {css, Global, GlobalProps} from '@emotion/core';
import {graphql, useStaticQuery} from 'gatsby';
import GatsbyImage from 'gatsby-image';
import {Fragment, useMemo} from 'react';
import {MIN_MOBILE_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {linkCss} from '../../styles/css';
import {fontFamilyArrayToString, headerFontFamily} from '../../styles/fonts';
import {font100, font80, rhythm0_125, rhythm0_25, rhythm1, rhythm2} from '../../styles/typography';
import {Lightbox} from '../../types/Lightbox';
import {Container} from '../Container';
import {LightboxLink, Link} from '../Link';
import {MainMenu} from '../MainMenu';
import {SiteLinks} from '../SiteLinks';

const headerCss = css({
  position: 'relative',
  zIndex: 100,
  display: 'block',
});

const headerContainerCss = css({
  display: 'flex',
  justifyContent: 'space-between',
  height: rhythm2,
  alignItems: 'center',

  '@media print': {
    display: 'block',
    height: 'auto',
    alignItems: 'flex-start',
  },
});

const profilePictureCss = css({
  verticalAlign: 'middle',
  width: `${rhythm1} !important`,
  height: `${rhythm1} !important`,
});

const logoCss = css({
  ...font80,
  // lineHeight: typography.rhythm(2),

  [MIN_MOBILE_MEDIA_QUERY]: {
    ...font100,
    // lineHeight: typography.rhythm(2),
  },

  fontFamily: fontFamilyArrayToString(headerFontFamily),
  fontWeight: 800,
  whiteSpace: 'nowrap',
  // textTransform: 'uppercase',

  marginLeft: `-${rhythm0_125}`,
});

const profilePictureLinkCss = css({
  display: 'none',
  marginRight: rhythm0_25,

  '@media (min-width: 350px)': {
    display: 'inline-block',
  },
});

const logoSpanCss = css({
  color: 'inherit',
  textDecoration: 'none',

  fontWeight: 'inherit',
  lineHeight: rhythm2,
  display: 'inline-block',
  padding: `0 ${rhythm0_125}`,
  margin: `0 -${rhythm0_125}`,
});

const logoLinkCss = css(linkCss, logoSpanCss, {
  '&:hover, &:focus': {
    '& img': {
      filter: 'brightness(120%)',
    },
  },

  '&:focus': {
    outline: 'none',
  },
});

const mainMenuCss = css({
  '@media print': {
    display: 'none',
  },
});

const siteLinksCss = css({
  display: 'none',

  '@media print': {
    ...font80,
    display: 'block',

    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

const globalStyles: GlobalProps<unknown>['styles'] = {
  '.header-logo-profile-picture': {
    display: 'inline-block',
  },
  '.header-logo': {
    color: 'inherit',
    textShadow: 'none',
  },
};

export type Props = {
  className?: string,
  noLogoLink?: boolean,
};

export const Header = (props: Props) => {
  const {className, noLogoLink} = props;

  const {
    site,
    profilePicture: {childImageSharp: profilePictureSharp},
  } = useStaticQuery(query);

  const lightbox: Lightbox = useMemo(
    () => ({
      src: profilePictureSharp.original.src,
      title: site.title,
      group: false,
      thumbnail: {
        fixed: profilePictureSharp.fixed,
      },
    }),
    [profilePictureSharp, site],
  );

  return (
    <Fragment>
      <header css={headerCss} className={`header ${className || ''}`}>
        <Global styles={globalStyles} />

        <Container css={headerContainerCss}>
          <div css={logoCss} className="header-logo">
            <div className="header-logo-profile-picture">
              <LightboxLink lightbox={lightbox} css={[logoLinkCss, profilePictureLinkCss]}>
                <GatsbyImage
                  css={profilePictureCss}
                  alt={site.title}
                  fixed={profilePictureSharp.fixed}
                  loading="eager"
                  fadeIn={false}
                />
              </LightboxLink>
            </div>

            {noLogoLink && (
              <span css={logoSpanCss} data-text={site.title}>
                {site.title}
              </span>
            )}

            {!noLogoLink && (
              <Link href="index" css={logoLinkCss}>
                {site.title}
                {/* <GatsbyImage alt={formatMessage(messages.logoImageAlt)} {...logo.childImageSharp} imgStyle={{height: rhythm1, width: rhythm1}} /> */}
              </Link>
            )}
          </div>

          <div css={mainMenuCss}>
            <MainMenu />
          </div>

          <div css={siteLinksCss}>
            <SiteLinks />
          </div>
        </Container>
      </header>
    </Fragment>
  );
};

Header.whyDidYouRender = true;

const query = graphql`
  query {
    site: settingsYaml(settings: {eq: "site"}) {
      title
    }

    profilePicture: file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "profile-picture-round.png"}) {
      childImageSharp {
        original {
          src
        }

        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
