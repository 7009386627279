import {createContext} from 'react';

type NotificationModal = {
  title?: React.ReactNode,
  body: React.ReactNode,
};

export type ModalsContext = {
  notify(modal: NotificationModal): number,
  close(id: number): void,
};

export const ModalsContext = createContext<ModalsContext>(undefined as any);
