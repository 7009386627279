import {css, GlobalProps} from '@emotion/core';
import {MIN_TABLET_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {focusTransitionDuration} from './animations';
import {colors} from './colors';
import {font60, font80, rhythm0_25, rhythm0_5, rhythm1} from './typography';
import {rhythm0_125, rhythm0_375, rhythm0_75, rhythm10, rhythm12, rhythm13, rhythm15, rhythm1_25, rhythm1_5, rhythm2, rhythm25, rhythm3, rhythm4, rhythm5} from './typography-node';

export const gatsbyImagePlaceholderStyle = {
  filter: 'blur(10px)',
};

export const externalLinkCss = css({
  textDecoration: 'underline',
  color: 'inherit',
});

export const gradient135deg = 'linear-gradient(135deg, rgba(0,200,255,1) 0%, rgba(255,151,0,1) 50%, rgba(84,29,255,1) 100%)';

export const gradient90deg = 'linear-gradient(90deg, rgba(0,200,255,1) 0%, rgba(255,151,0,1) 50%, rgba(84,29,255,1) 100%)';

export const gradient45deg = 'linear-gradient(45deg, rgba(0,200,255,1) 0%, rgba(255,151,0,1) 50%, rgba(84,29,255,1) 100%)';

export const linkCss = css({
  borderRadius: '2px',
  outline: 'none',

  transition: `
    color ${focusTransitionDuration}ms ease-out,
    background-color ${focusTransitionDuration}ms ease-out
  `,

  color: colors.links.text,
  fontWeight: 700,

  '&:active': {
    color: colors.links.active.text,
    background: colors.links.active.background,
  },

  '&:focus': {
    color: colors.links.hover.text,
    background: colors.links.hover.background,
  },

  '&:hover': {
    color: colors.links.hover.text,
    background: colors.links.hover.background,
  },

  textDecoration: 'none',
});

export const commonBoxShadow = `0 3px 10px ${colors.shadow}`;

export const baseStyles: GlobalProps<unknown>['styles'] = {
  ':root': {
    '--window-height': '100vh',
    '--rhythm25': rhythm25,
    '--rhythm15': rhythm15,
    '--rhythm13': rhythm13,
    '--rhythm12': rhythm12,
    '--rhythm10': rhythm10,
    '--rhythm5': rhythm5,
    '--rhythm4': rhythm4,
    '--rhythm3': rhythm3,
    '--rhythm2': rhythm2,
    '--rhythm1_5': rhythm1_5,
    '--rhythm1_25': rhythm1_25,
    '--rhythm1': rhythm1,
    '--rhythm0_75': rhythm0_75,
    '--rhythm0_5': rhythm0_5,
    '--rhythm0_375': rhythm0_375,
    '--rhythm0_25': rhythm0_25,
    '--rhythm0_125': rhythm0_125,
  },
  // 'html, body': {
  //   // height: '100vh',
  //   // overflow: 'auto',
  //   // background: '#fff',
  //   // background: colors.body.background,
  // },
  body: {
    background: 'white',
  },
  img: {
    margin: 0,
  },
  [`
    ul,
    ol
  `]: {
    pageBreakBefore: 'avoid',
  },
  li: {
    margin: 0,
  },
  hr: {
    margin: `${rhythm0_5} 0`,
  },
  '.grecaptcha-badge': {
    display: 'none',
  },
  figure: {
    marginBottom: rhythm1,
    position: 'relative',

    // [`
    //   & > img,
    //   & > a
    // `]: {
    //   borderRadius: '5px',

    //   boxShadow: `
    //     0 3px 10px rgba(0, 0, 0, 0.5)
    //   `,
    // },
  },
  figcaption: {
    ...font80,
    lineHeight: 1.1,

    [MIN_TABLET_MEDIA_QUERY]: {
      ...font60,
      lineHeight: 1.1,
    },

    // position: 'absolute',
    // bottom: 0,
    // // left: 0,
    // right: 0,
    // maxWidth: '90%',
    // color: 'white',
    // // textShadow: '0 2px 10px black',
    padding: rhythm0_25,
    textAlign: 'justify',
    background: colors.backgrounds.light,

    '& p:last-child': {
      marginBottom: 0,
    },
  },
  code: {
    borderRadius: '2px',
  },
};

// export const gradient135deg = 'linear-gradient(135deg, rgba(111,49,170,1) 0%, rgba(252,70,70,1) 50%, rgba(252,227,69,1) 100%)';
// export const gradient45deg = 'linear-gradient(45deg, rgba(111,49,170,1) 0%, rgba(252,70,70,1) 50%, rgba(252,227,69,1) 100%)';

// export const smallBoxShadow = `0 1px 4px rgba(0, 0, 0, .2)`;
