import {css} from '@emotion/core';
import {LARGE_DISPLAY_WIDTH} from 'typography-breakpoint-constants';
import {rhythm0_5} from '../../styles/typography';

export const containerCss = css({
  padding: `0 ${rhythm0_5}`,
  maxWidth: LARGE_DISPLAY_WIDTH,
  marginLeft: 'auto',
  marginRight: 'auto',
});

export type Props = {
  children: React.ReactNode,
  className?: string,
};

export const Container = (props: Props) => {
  const {children, className} = props;

  return (
    <div css={[containerCss]} className={className}>
      {children}
    </div>
  );
};
