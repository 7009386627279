export const trackGtagEvent = (action: string, getParams = () => ({} as Record<string, string>)) => {
  if (process.env.NODE_ENV !== 'production' || typeof gtag !== 'function') {
    return;
  }

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const track = () => {
    const params = {
      send_to: process.env.GOOGLE_ANALYTICS_TRACKING_ID!,
      ...getParams,
    };

    gtag('event', action, params);
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(track);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(track, 32);
  }
};
