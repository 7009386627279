import {useLocation} from '@reach/router';
import {Fragment, useContext, useMemo} from 'react';
import {AllPagesContext} from '../../contexts/AllPagesContext';
import {Link} from '../Link';

if (!process.env.SITE_URL) {
  throw new Error(`${__filename} Missing process.env.SITE_URL`);
}

export const SiteLinks = () => {
  const location = useLocation();

  const {pagesByPage} = useContext(AllPagesContext);

  const siteUrl = useMemo(
    () => new URL(`${process.env.SITE_URL}${pagesByPage.index.path}`),
    [pagesByPage],
  );

  return (
    <Fragment>
      <Link href={pagesByPage.index.path}>
        {`${siteUrl.host}${siteUrl.pathname}`}
      </Link>

      <br />

      <Link href={`${location.pathname}${location.search}${location.hash}`}>
        {`${location.host}${location.pathname}${location.search}${location.hash}`}
      </Link>
    </Fragment>
  );
};
