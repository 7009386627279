import {formatISO} from 'date-fns';
import {graphql, useStaticQuery} from 'gatsby';
import {Fragment, useContext, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';
import {LdJson} from './LdJson';

if (!process.env.SITE_URL) {
  throw new Error(`${__filename} Missing process.env.SITE_URL`);
}

export const Seo = () => {
  const {
    site: {image: {childImageSharp: {resize: {src: siteImagePath}}}, ...site},
  } = useStaticQuery(query);

  const {page, title, path, same, featuredImage, prev, next, type, createdAt, updatedAt} = useContext(CurrentPageContext);

  const ogType = useMemo(
    () => {
      if (type === 'blog-post') {
        return 'article';
      }

      return 'website';
    },
    [type],
  );

  // const titleTemplate = page !== 'index' ? `%s — ${site.title}` : '%s';
  const titleTemplate = title ? `%s - ${site.title}` : site.title;

  // const description = undefined; // TODO:

  const {locale, /* formatters */} = useIntl();

  const imageUrl = featuredImage
    ? `${process.env.SITE_URL!}${featuredImage.seo.src}`
    : `${process.env.SITE_URL!}${siteImagePath}`;

  const url = `${process.env.SITE_URL!}${path}`;

  // TODO: check for urls with /:param/
  const sameUrls = same.map(({locale, path}) => ({locale, url: `${process.env.SITE_URL!}${path}`}));

  const prevUrl = prev && `${process.env.SITE_URL!}${prev.path}`;
  const nextUrl = next && `${process.env.SITE_URL!}${next.path}`;

  // const title = useMemo(
  //   () => {
  //     if (pageTitle) {
  //       const intlValuesProxy = new Proxy(intlValues, {
  //         has: () => true,
  //         get: (target, prop: string) => prop in target ? target[prop] : '',
  //       });

  //       return formatters.getMessageFormat(pageTitle).format(intlValuesProxy);
  //     }
  //   },
  //   [pageTitle, intlValues, formatters],
  // );

  return (
    <Fragment>
      <Helmet defaultTitle={site.title} titleTemplate={titleTemplate}>
        <html lang={locale} prefix="og: http://ogp.me/ns#" />

        <title>{title}</title>

        <link rel="canonical" href={url} />

        {prevUrl && <link rel="prev" href={prevUrl} />}
        {nextUrl && <link rel="next" href={nextUrl} />}

        {sameUrls.map(({locale, url}) => (
          <link key={`alternate-${locale}`} rel="alternate" hrefLang={locale} href={url} />
        ))}

        {/* {themeColor && <meta name="theme-color" content={themeColor} />} */}
        <meta name="application-name" content={site.title} />

        {/* {themeColor && <meta name="msapplication-TileColor" content={themeColor} />} */}

        <meta property="og:title" content={title} />
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={site.title} />
        <meta property="og:locale" content={locale} />

        {ogType === 'article' && createdAt && <meta property="og:article:published_time" content={formatISO(createdAt, {representation: 'date'})} />}
        {ogType === 'article' && updatedAt && <meta property="og:article:modified_time" content={formatISO(updatedAt, {representation: 'date'})} />}

        {/* {description && <meta property="og:description" content={description} />} */}

        {sameUrls
          .filter((sameUrl) => sameUrl.locale !== locale)
          .map(({locale}) => (
            <meta key={`og:locale:alternate-${locale}`} property="og:locale:alternate" content={locale} />
          ))}

        {<meta property="og:image" content={imageUrl} />}

        {/* {facebookAppId && <meta property="fb:app_id" content={facebookAppId} />} */}

      </Helmet>

      <LdJson />
    </Fragment>
  );
};

const query = graphql`
  query {
    site: settingsYaml(settings: {eq: "site"}) {
      title
      image {
        childImageSharp {
          resize(width: 1200) {
            src
          }
        }
      }
    }
  }
`;
