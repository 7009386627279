import {ClassNames, css} from '@emotion/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Portal} from 'react-portal';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {colors} from '../styles/colors';
import {commonBoxShadow} from '../styles/css';
import {rhythm0_125, rhythm0_5, rhythm1} from '../styles/typography';
import {AnimatedCssGetter} from '../types/AnimatedCssGetter';
import {ModalsContext} from './ModalsContext';

const notificationsCss = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 101,
});

const notificationWrapperCss = css({
  display: 'flex',
  justifyContent: 'center',
  margin: `${rhythm0_5} 0`,
});

const notificationCss = (type: 'success') => css({
  margin: '0 auto',
  borderRadius: rhythm1,
  boxShadow: commonBoxShadow,
  color: colors.modals.notifications.success.text,
  background: colors.modals.notifications.success.background,
  textAlign: 'center',
  display: ['-ms-grid', 'grid'],
  gridTemplateColumns: 'auto auto min-content',
  padding: `${rhythm0_125} ${rhythm0_5}`,
  gap: rhythm0_5,
  overflow: 'hidden',
});

const notificationTitleCss = css({
  fontWeight: 600,
});

const notificationBody = css({
  display: 'inline',
});

const notificationCloseCss = css({
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  verticalAlign: 'middle',
});

const notificationDuration = 10000;
const notificationAnimationDuration = 500;

const notificationAnimatedCss: AnimatedCssGetter = (css) => ({
  enter: css({
    opacity: 0,
    transform: 'translateY(-20px)',
  }),
  enterActive: css({
    opacity: 1,
    transform: 'none',
    transition: `transform ${notificationAnimationDuration}ms ease-out, opacity ${notificationAnimationDuration}ms ease-out`,
  }),
  exit: css({
    opacity: 1,
    transform: 'none',
  }),
  exitActive: css({
    opacity: 0,
    transform: 'translateY(-20px)',
    transition: `transform ${notificationAnimationDuration}ms ease-out, opacity ${notificationAnimationDuration}ms ease-out`,
  }),
});

type Props = {
  children: React.ReactNode,
}

let counter = 0;

export const ModalsProvider = (props: Props) => {
  const {children} = props;

  const [modals, setModals] = useState<any[]>([]);

  const timeouts = useRef<ReturnType<typeof setTimeout>[]>([]);

  useEffect(
    () => { timeouts.current.forEach((timeout) => { clearTimeout(timeout); }); },
    [],
  );

  const close = useCallback(
    (id: number) => setModals((prev) => prev.filter((prevModal) => prevModal.id !== id)),
    [],
  );

  const context: ModalsContext = useMemo(
    () => ({
      close,
      notify: (modal) => {
        counter += 1;

        const id = counter;

        setModals((prev) => [...prev, {
          id,
          modal,
          type: 'notification',
        }]);

        timeouts.current.push(setTimeout(
          () => { close(id); },
          notificationDuration,
        ));

        return id;
      },
    }),
    [close],
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     context.notify({title: 'ciao', body: 'ciapo'});
  //   }, 5000);

  //   return () => { clearInterval(interval); };
  // }, []);

  const notifications = useMemo(
    () => modals.filter((modal) => modal.type === 'notification')
      .reverse(),
    [modals],
  );

  const handleCloseNotification = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      close(Number(e.currentTarget.dataset.id));
    },
    [close],
  );

  return (
    <ModalsContext.Provider value={context}>
      {children}

      <ClassNames>
        {({css}) => (
          <Portal>
            <div css={notificationsCss}>
              <TransitionGroup component={null}>
                {notifications.map(({id, modal}) => (
                  <CSSTransition
                    key={id}
                    timeout={notificationAnimationDuration}
                    classNames={notificationAnimatedCss(css)}
                  >
                    <div css={notificationWrapperCss}>
                      <div css={notificationCss}>
                        {modal.title && (
                          <div css={notificationTitleCss}>
                            {modal.title}
                          </div>
                        )}

                        <div css={notificationBody}>
                          {modal.body}
                        </div>

                        <div>
                          <button css={notificationCloseCss} type="button" data-id={id} onClick={handleCloseNotification}>
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </Portal>
        )}
      </ClassNames>
    </ModalsContext.Provider>
  );
};
