import {forwardRef, useEffect, useState} from 'react';
import {decodeSafeText} from './decodeSafeText';

export type Props = {
  text: string,
  lazy?: boolean,
  children?(decodedText: string | null): React.ReactNode,
};

export type SafeTextHandler = {
  decode(): void,
};

export const SafeText = forwardRef<SafeTextHandler, Props>((props, ref) => {
  const {text, lazy, children} = props;

  const [decodedText, setDecodedText] = useState<{text: string, decoded: string} | null>(null);

  useEffect(
    () => {
      if (ref) {
        const handler = {
          decode: () => {
            setDecodedText((prev) => {
              if (prev?.text !== text) {
                return {
                  text,
                  decoded: decodeSafeText(text),
                };
              }

              return prev;
            });
          },
        };

        if (typeof ref === 'function') {
          ref(handler);
        } else {
          ref.current = handler;
        }
      }
    },
    [text, ref],
  );

  useEffect(
    () => {
      if (!lazy) {
        setDecodedText((prev) => {
          if (prev?.text !== text) {
            return {
              text,
              decoded: decodeSafeText(text),
            };
          }

          return prev;
        });
      }
    },
    [lazy, text],
  );

  if (children) {
    return children(decodedText && decodedText.decoded);
  }

  return decodedText?.decoded || null;
});
