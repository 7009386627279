import {css} from '@emotion/core';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MDXProvider} from '@mdx-js/react';
import {externalLinkCss, linkCss} from '../../styles/css';
import {font50, rhythm0_125} from '../../styles/typography';
import {Img} from '../Img/Img';
import {isExternalLink, Link} from '../Link';

const externalLinkSymbolCss = css({
  ...font50,
  lineHeight: 'inherit',
  // textDecoration: 'none',
  verticalAlign: 'middle',
  marginLeft: rhythm0_125,
});

const components = {
  a: (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
    const {href, download, children, ...linkProps} = props;

    const isExternal = href && isExternalLink(href);

    return (
      <Link href={href} download={download} {...linkProps} css={isExternal ? externalLinkCss : linkCss}>
        {children}

        {isExternal && (
          <span css={externalLinkSymbolCss}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </span>
        )}
      </Link>
    );
  },
  img: (props: any) => {
    const {
      alt,
      title,

      ['data-fixed']: fixedStr,
      ['data-fluid']: fluidStr,
      ['data-lightbox']: lightboxStr,
      ['data-lightbox-src']: lightboxSrc,

      ...imgProps
    } = props;

    const fixed = fixedStr && JSON.parse(fixedStr);
    const fluid = fluidStr && JSON.parse(fluidStr);

    const lightbox = lightboxStr === undefined
      ? undefined
      : {
        group: lightboxStr === '' ? true : lightboxStr,
        src: lightboxSrc,
      };

    return (
      <Img
        alt={alt}
        title={title || alt}
        {...imgProps}
        fixed={fixed}
        fluid={fluid}
        lightbox={lightbox}
      />
    );
  },
};

type Props = {
  children: React.ReactNode,
};

export const MdxBody = (props: Props) => {
  const {children} = props;

  return (
    <MDXProvider components={components}>
      {children}
    </MDXProvider>
  );
};

MdxBody.whyDidYouRender = true;
