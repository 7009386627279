import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {Button, Props} from './Button';

const defaultButtonCss = css({
  color: colors.buttons.default.text,
  borderColor: colors.buttons.default.border,
  backgroundColor: colors.buttons.default.background,

  '&:hover': {
    backgroundColor: colors.buttons.default.focus.background,
    borderColor: colors.buttons.default.focus.border,
  },

  '&:focus': {
    backgroundColor: colors.buttons.default.focus.background,
    borderColor: colors.buttons.default.focus.border,
  },

  '&:active': {
    backgroundColor: colors.buttons.default.active.background,
    borderColor: colors.buttons.default.active.border,
  },
});

export const DefaultButton = (props: Props) => (
  <Button css={defaultButtonCss} {...props} />
);
