import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {Button, Props} from './Button';

const successButtonCss = css({
  color: colors.buttons.success.text,
  borderColor: colors.buttons.success.border,
  backgroundColor: colors.buttons.success.background,

  '&:hover': {
    backgroundColor: colors.buttons.success.focus.background,
    borderColor: colors.buttons.success.focus.border,
  },

  '&:focus': {
    backgroundColor: colors.buttons.success.focus.background,
    borderColor: colors.buttons.success.focus.border,
  },

  '&:active': {
    backgroundColor: colors.buttons.success.active.background,
    borderColor: colors.buttons.success.active.border,
  },

  '&[disabled]': {
    backgroundColor: colors.buttons.success.disabled.background,
    borderColor: colors.buttons.success.disabled.border,

    cursor: 'not-allowed',
  },
});

export const SuccessButton = (props: Props) => (
  <Button css={successButtonCss} {...props} />
);
