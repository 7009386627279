import {css} from '@emotion/core';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation} from '@reach/router';
import {useCallback, useContext, useEffect, useRef} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {PortalWithState} from 'react-portal';
import {MIN_TABLET_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {UserConsentContext} from '../../contexts/UserConsentContext';
import {colors} from '../../styles/colors';
import {externalLinkCss} from '../../styles/css';
import {rhythm0_5, rhythm1} from '../../styles/typography';
import {PrimaryButton} from '../Button';
import {Container} from '../Container';
import {Link} from '../Link';

const messages = defineMessages({
  infoMessage: {id: 'components.CookieBanner.infoMessage', defaultMessage: 'This website uses cookies to improve your experience on our website. <cookie-policy-link>Learn More.</cookie-policy-link>'},
  acceptButton: {id: 'components.CookieBanner.acceptButton', defaultMessage: 'Allow cookies'},
});

const cookieBannerCss = css({
  zIndex: 100,
  fontWeight: 600,
  background: colors.cookieBanner.background,
  backdropFilter: 'blur(5px)',
  color: colors.cookieBanner.text,
  // background: 'black',
  // color: 'white',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  padding: `${rhythm1} 0`,

  '& a': externalLinkCss,

  '@media print': {
    display: 'none',
  },
});

const cookieBannerContainerCss = css({
  display: ['-ms-grid', 'grid'],
  gridTemplateColumns: '1fr',
  msGridColumns: '1fr',
  gridGap: rhythm0_5,
  alignItems: 'center',

  [MIN_TABLET_MEDIA_QUERY]: {
    gridTemplateColumns: 'auto min-content',
    msGridColumns: 'auto min-content',
  },
});

const messageCss = css({
  // textAlign: 'justify',
});

const buttonsCss = css({
  textAlign: 'right',
});

export const CookieBanner = () => {
  const {allow, userConsent} = useContext(UserConsentContext);

  const mutationObserverRef = useRef<MutationObserver>();

  useEffect(
    () => {
      return () => { mutationObserverRef.current?.disconnect(); };
    },
    [],
  );

  // Handles the "I don't care about cookies" browser extension
  const handleIDontCareAboutCookiesExtension = useCallback(
    (el: HTMLDivElement) => {
      if (!el) { return; }

      if (window.getComputedStyle(el).display.startsWith('none')) {
        allow();
      } else {
        mutationObserverRef.current = new MutationObserver(() => {
          if (window.getComputedStyle(el).display.startsWith('none')) {
            mutationObserverRef.current?.disconnect();

            allow();
          }
        });

        mutationObserverRef.current.observe(el, {
          attributes: true,
          attributeFilter: ['style', 'class'],
        });
      }
    },
    [allow],
  );

  // const [show, setShow] = useState(false);

  // useEffect(
  //   () => {
  //     setTimeout(
  //       () => {
  //         if (userConsent) {
  //           setShow(userConsent !== 'allowed');
  //         }
  //       },
  //       1000,
  //     );
  //   },
  //   [userConsent],
  // );

  const location = useLocation();

  if (location.search.includes('no-cookie-banner')) {
    return null;
  }

  if (userConsent === 'allowed') {
    return null;
  }

  return (
    <PortalWithState defaultOpen>
      {() => (
        <div>
          <div css={cookieBannerCss} className="cookie-banner" ref={handleIDontCareAboutCookiesExtension}>
            <Container css={cookieBannerContainerCss}>
              <div css={messageCss}>
                <FormattedMessage
                  {...messages.infoMessage}
                  values={{
                    'cookie-policy-link': (children: React.ReactNode) => (
                      <Link href="privacy-policy?no-cookie-banner" target="_blank">
                        {children}
                      </Link>
                    ),
                  }}
                />
              </div>
              <div css={buttonsCss}>
                <PrimaryButton onClick={allow}>
                  <FontAwesomeIcon icon={faCheck} fixedWidth />
                  {' '}
                  <FormattedMessage {...messages.acceptButton} />
                </PrimaryButton>

                {/* <DefaultButton onClick={customizeCookies}>
                  <FontAwesomeIcon icon={faCog} fixedWidth />
                  {' '}
                  <FormattedMessage {...messages.customizeButton} />
                </DefaultButton> */}
              </div>
            </Container>
          </div>
        </div>
      )}
    </PortalWithState>
  );
};
