const Typography = require('typography');
const {MIN_LARGER_DISPLAY_MEDIA_QUERY, MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY} = require('typography-breakpoint-constants');
const {colors} = require('./colors-node');
const {bodyFontFamily, headerFontFamily} = require('./fonts');

const baseFontSize = 20;

const typography = new Typography({
  headerFontFamily,
  bodyFontFamily,

  includeNormalize: true,
  // headerWeight: 600,
  // bodyWeight: 300,
  baseFontSize: `${baseFontSize}px`,
  headerColor: colors.headers,
  bodyColor: colors.body.text,
  scaleRatio: 2,
  // rhythmUnit: 'px',

  overrideStyles: (vr, options, styles) => ({
    html: {
      // backgroundColor: colors.body.background,
      fontSize: `${baseFontSize}px`,
      // letterSpacing: '0.5px',
    },
    // dl: {
    //   ...vr.adjustFontSizeTo('80%'),

    //   lineHeight: vr.rhythm(0.8),
    // },
    // sup: {
    //   fontSize: '0.5em',
    // },
    // 'h1, h2, h3, h4, h5, h6': {
    //   position: 'relative',
    // },
    // strong: {
    //   fontWeight: 600,
    // },

    [MOBILE_MEDIA_QUERY]: {
      // Make baseFontSize on mobile 16px.
      html: {
        fontSize: `${baseFontSize * (16 / 16)}px`,
      },
    },
    [TABLET_MEDIA_QUERY]: {
      html: {
        fontSize: `${baseFontSize * (17 / 16)}px`,
      },
    },
    [MIN_LARGER_DISPLAY_MEDIA_QUERY]: {
      html: {
        fontSize: `${baseFontSize * (19 / 16)}px`,
      },
    },
    '@media print': {
      html: {
        // background: 'none',
        // color: 'black',
        fontSize: `${baseFontSize * (14 / 16)}px`,
      },
      // '*': {
      //   color: 'black !important',
      // },
    },
  }),
});

module.exports = typography;

module.exports.baseFontSize = baseFontSize;

module.exports.font500 = typography.adjustFontSizeTo('500%');
module.exports.font400 = typography.adjustFontSizeTo('400%');
module.exports.font300 = typography.adjustFontSizeTo('300%');
module.exports.font250 = typography.adjustFontSizeTo('250%');
module.exports.font200 = typography.adjustFontSizeTo('200%');
module.exports.font150 = typography.adjustFontSizeTo('150%');
module.exports.font100 = typography.adjustFontSizeTo('100%');
module.exports.font80 = typography.adjustFontSizeTo('80%');
module.exports.font60 = typography.adjustFontSizeTo('60%');
module.exports.font50 = typography.adjustFontSizeTo('50%');

module.exports.rhythm25 = typography.rhythm(25);
module.exports.rhythm15 = typography.rhythm(15);
module.exports.rhythm13 = typography.rhythm(13);
module.exports.rhythm12 = typography.rhythm(12);
module.exports.rhythm10 = typography.rhythm(10);
module.exports.rhythm5 = typography.rhythm(5);
module.exports.rhythm4 = typography.rhythm(4);
module.exports.rhythm3 = typography.rhythm(3);
module.exports.rhythm2 = typography.rhythm(2);
module.exports.rhythm1_5 = typography.rhythm(1.5);
module.exports.rhythm1_25 = typography.rhythm(1.25);
module.exports.rhythm1 = typography.rhythm(1);
module.exports.rhythm0_75 = typography.rhythm(0.75);
module.exports.rhythm0_5 = typography.rhythm(0.5);
module.exports.rhythm0_375 = typography.rhythm(0.375);
module.exports.rhythm0_25 = typography.rhythm(0.25);
module.exports.rhythm0_125 = typography.rhythm(0.125);

module.exports.typographyStyles = typography.createStyles();
