import {css} from '@emotion/core';
import GatsbyImage, {FixedObject, FluidObject} from 'gatsby-image';
import {useMemo} from 'react';
import {gatsbyImagePlaceholderStyle} from '../../styles/css';
import {Lightbox} from '../../types/Lightbox';
import {LightboxLink} from '../Link';

const fixedImageLinkCss = css({
  display: 'inline-block',
});

const fluidImageLinkCss = css({
  display: 'block',
});

const imageLinkCss = css({
  overflow: 'hidden',
  // borderRadius: '0 !important',
  position: 'relative',

  // '&::after': {
  //   ...typography.adjustFontSizeTo('200%'),
  //   fontFamily: fontFamilyArrayToString(uiFontFamily),
  //   content: '\'👁\'',
  //   transition: `opacity ${animationDuration / 2}ms ease-out`,
  //   background: 'radial-gradient(circle, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, 1) 150%)',
  //   // background: 'rgba(0, 0, 0, .3)',
  //   position: 'absolute',
  //   opacity: 0,
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },

  '.gatsby-image-wrapper': {
    verticalAlign: 'middle',
  },
});

type Props = React.ImgHTMLAttributes<any> & {
  fixed?: FixedObject,
  fluid?: FluidObject,
  lightbox?: {
    group: Lightbox['group'],
    src: string,
  },
};

export const Img = (props: Props) => {
  const {src, fixed, fluid, lightbox: lightboxProp, title, alt, ...imgProps} = props;

  const lightbox: Lightbox | undefined = useMemo(
    () => {
      if (lightboxProp) {
        return {
          title,
          group: lightboxProp.group,
          src: lightboxProp.src,
          thumbnail: {
            src,
            fixed,
            fluid,
          },
        };
      }

      return undefined;
    },
    [lightboxProp, src, fixed, fluid, title],
  );

  const image = useMemo(
    () => (
      fixed || fluid
        ? (
          <GatsbyImage
            placeholderStyle={gatsbyImagePlaceholderStyle}
            alt={alt}
            title={title}
            fixed={fixed}
            fluid={fluid}
          />
        )
        : (
          <img src={src} alt={alt} title={title} {...imgProps} />
        )
    ),
    [fixed, fluid, src, title, alt, imgProps],
  );

  if (lightbox) {
    return (
      <LightboxLink
        lightbox={lightbox}
        css={[fixed && fixedImageLinkCss, fluid && fluidImageLinkCss, imageLinkCss]}
      >
        {image}
      </LightboxLink>
    );
  }

  return image;
};
