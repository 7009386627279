import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {Button, Props} from './Button';

const warningButtonCss = css({
  color: colors.buttons.warning.text,
  borderColor: colors.buttons.warning.border,
  backgroundColor: colors.buttons.warning.background,

  '&:hover': {
    backgroundColor: colors.buttons.warning.focus.background,
    borderColor: colors.buttons.warning.focus.border,
  },

  '&:focus': {
    backgroundColor: colors.buttons.warning.focus.background,
    borderColor: colors.buttons.warning.focus.border,
  },

  '&:active': {
    backgroundColor: colors.buttons.warning.active.background,
    borderColor: colors.buttons.warning.active.border,
  },

  '&[disabled]': {
    backgroundColor: colors.buttons.warning.disabled.background,
    borderColor: colors.buttons.warning.disabled.border,

    cursor: 'not-allowed',
  },
});

export const WarningButton = (props: Props) => (
  <Button css={warningButtonCss} {...props} />
);
