import {css} from '@emotion/core';
import {colors} from '../../styles/colors';
import {Button, Props} from './Button';

const primaryButtonCss = css({
  color: colors.buttons.primary.text,
  borderColor: colors.buttons.primary.border,
  backgroundColor: colors.buttons.primary.background,

  ':hover': {
    backgroundColor: colors.buttons.primary.focus.background,
    borderColor: colors.buttons.primary.focus.border,
  },

  ':focus': {
    backgroundColor: colors.buttons.primary.focus.background,
    borderColor: colors.buttons.primary.focus.border,
  },

  ':active': {
    backgroundColor: colors.buttons.primary.active.background,
    borderColor: colors.buttons.primary.active.border,
  },

  '&[disabled]': {
    backgroundColor: colors.buttons.primary.disabled.background,
    borderColor: colors.buttons.primary.disabled.border,

    cursor: 'not-allowed',
  },
});

export const PrimaryButton = (props: Props) => (
  <Button css={primaryButtonCss} {...props} />
);
