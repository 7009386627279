import { css } from '@emotion/core';
import {
  faFacebook,
  faGithub,
  faGitlab,
  faLinkedin,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faMapMarker,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  MIN_MOBILE_MEDIA_QUERY,
  MIN_TABLET_MEDIA_QUERY,
} from 'typography-breakpoint-constants';
import { colors } from '../../styles/colors';
import { linkCss } from '../../styles/css';
import {
  fontFamilyArrayToString,
  headerFontFamily,
  uiFontFamily,
} from '../../styles/fonts';
import {
  font100,
  font60,
  font80,
  rhythm0_125,
  rhythm0_25,
  rhythm1,
} from '../../styles/typography';
import { Lightbox } from '../../types/Lightbox';
import { Container } from '../Container';
import { LightboxLink, Link } from '../Link';
import { SafeText } from '../Safe';
import { SiteLinks } from '../SiteLinks';

const messages = defineMessages({
  businessTaxId: {
    id: 'components.Footer.businessTaxId',
    defaultMessage: 'C.F.: {taxId}',
  },
  businessVatId: {
    id: 'components.Footer.businessVatId',
    defaultMessage: 'P.IVA: {vatId}',
  },
  businessAddress: {
    id: 'components.Footer.businessAddress',
    defaultMessage: `{address}, {postalCode} {city} ({state}) {
    country, select,
      it { Italy}
  }`,
  },
  facebook: { id: 'components.Footer.facebook', defaultMessage: 'Facebook' },
  linkedin: { id: 'components.Footer.linkedin', defaultMessage: 'LinkedIn' },
  whatsapp: { id: 'components.Footer.whatsapp', defaultMessage: 'WhatsApp' },
  telegram: { id: 'components.Footer.telegram', defaultMessage: 'Telegram' },
  github: { id: 'components.Footer.github', defaultMessage: 'GitHub' },
  gitlab: { id: 'components.Footer.gitlab', defaultMessage: 'GitLab' },
});

const footerCss = css({
  ...font80,
  display: 'block',

  // textAlign: 'center',

  // marginTop: typography.rhythm(2),

  background: colors.backgrounds.dark,
  color: 'white',

  // background: colors.footer.background,
  // textAlign: 'center',
  // padding: `${typography.rhythm(0.5)} 0`,
  // display: 'none',
  // height: typography.rhythm(2),
  // lineHeight: typography.rhythm(2),
  padding: `${rhythm1} 0 ${rhythm1}`,

  [MIN_TABLET_MEDIA_QUERY]: {
    textAlign: 'left',
  },

  '@media print': {
    background: 'none',
    color: colors.body.text,
    pageBreakInside: 'avoid',
  },
});

const footerContainerCss = css({
  display: ['-ms-grid', 'grid'],
  gridTemplateColumns: '1fr 1fr',
  msGridColumns: '1fr 1fr',
  gridRowGap: rhythm1,
  // justifyContent: 'space-between',
  // flexWrap: 'wrap',

  [MIN_TABLET_MEDIA_QUERY]: {
    gridTemplateColumns: '3fr 1fr 1fr',
    msGridColumns: '3fr 1fr 1fr',
    // flexWrap: 'nowrap',
  },

  '@media print': {
    display: 'block',
  },
});

const firstFooterColumnCss = css({
  marginTop: 0,
  gridColumn: '1 / span 2',
  msGridColumn: 1,
  msGridColumnSpan: 2,
  // flexBasis: '100%',

  [MIN_TABLET_MEDIA_QUERY]: {
    gridColumn: 1,
    msGridColumnSpan: 1,
    // flexBasis: '60%',
  },
});

const secondFoorterColumnCss = css({
  msGridColumn: 1,
  msGridRow: 2,

  [MIN_TABLET_MEDIA_QUERY]: {
    msGridColumn: 2,
    msGridRow: 1,
  },
});

const thirdFooterColumnCss = css({
  msGridColumn: 2,
  msGridRow: 2,

  [MIN_TABLET_MEDIA_QUERY]: {
    msGridColumn: 3,
    msGridRow: 1,
  },
});

const siteLinksCss = css({
  ...font80,
  marginBottom: rhythm1,

  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
});

const footerColumnCss = css({
  // marginTop: rhythm1,
  // flexBasis: '50%',

  [MIN_TABLET_MEDIA_QUERY]: {
    // marginTop: 0,
    // flexBasis: '20%',
  },
});

const logoCss = css({
  ...font80,

  marginBottom: rhythm1,

  [MIN_MOBILE_MEDIA_QUERY]: {
    ...font100,
  },

  fontFamily: fontFamilyArrayToString(headerFontFamily),
  fontWeight: 800,
  whiteSpace: 'nowrap',
  // textTransform: 'uppercase',

  marginLeft: `-${rhythm0_125}`,
});

const logoLinkCss = css(linkCss, {
  color: 'inherit',
  textDecoration: 'none',

  fontWeight: 'inherit',
  display: 'inline-block',
  padding: `0 ${rhythm0_125}`,
  margin: `0 -${rhythm0_125}`,

  [`
    &:hover,
    &:focus
  `]: {
    '& img': {
      filter: 'brightness(120%)',
    },
  },

  '&:focus': {
    outline: 'none',
  },
});

const profilePictureLinkCss = css({
  display: 'none',
  marginRight: rhythm0_25,

  '@media (min-width: 350px)': {
    display: 'inline-block',
  },
});

const profilePictureCss = css({
  verticalAlign: 'middle',
  width: `${rhythm1} !important`,
  height: `${rhythm1} !important`,
});

const footerMenuCss = css({
  ...font60,
  fontFamily: fontFamilyArrayToString(uiFontFamily),

  // textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',

  '& a': {
    // display: 'block',
    fontWeight: 600,
    marginBottom: rhythm0_25,
  },

  '@media print': {
    display: 'none',
  },
});

const copyrightCss = css({
  ...font80,
});

const footerLinkCss = css(linkCss, {
  color: 'inherit',
});

export type Props = {
  className?: string;
};

export const Footer = (props: Props) => {
  const { className } = props;

  const {
    site,
    business,
    social,
    footerMenus: { nodes: footerMenus },
    profilePicture: { childImageSharp: profilePictureSharp },
  } = useStaticQuery(query);

  const { locale } = useIntl();

  const { links } = footerMenus.find((menu: any) => menu.locale === locale);

  const lightbox: Lightbox = useMemo(
    () => ({
      src: profilePictureSharp.original.src,
      title: site.title,
      group: false,
      thumbnail: {
        fixed: profilePictureSharp.fixed,
      },
    }),
    [profilePictureSharp, site],
  );

  return (
    <footer css={footerCss} className={`footer ${className || ''}`}>
      <Container css={footerContainerCss}>
        <div css={[footerColumnCss, firstFooterColumnCss]}>
          <div css={logoCss} className="logo">
            <LightboxLink
              lightbox={lightbox}
              css={[logoLinkCss, profilePictureLinkCss]}
            >
              <GatsbyImage
                css={profilePictureCss}
                alt={site.title}
                fixed={profilePictureSharp.fixed}
              />
            </LightboxLink>

            <Link href="index" css={logoLinkCss}>
              {site.title}
            </Link>
          </div>

          <div css={footerColumnCss}>
            <SafeText text={business.phoneSafe}>
              {(text) =>
                text && (
                  <Link href={`tel:${text}`} css={footerLinkCss}>
                    <FontAwesomeIcon icon={faPhone} fixedWidth /> {text}
                  </Link>
                )
              }
            </SafeText>

            <br />

            <SafeText text={business.emailSafe}>
              {(text) =>
                text && (
                  <Link href={`mailto:${text}`} css={footerLinkCss}>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth /> {text}
                  </Link>
                )
              }
            </SafeText>

            {/* <br />

            <Link css={footerLinkCss} href={`geo:${[business.geoPosition.lat, business.geoPosition.lon]}?q=${[business.geoPosition.lat, business.geoPosition.lon]}`}>
              <FontAwesomeIcon icon={faMapMarker} fixedWidth />
              {' '}
              <FormattedMessage {...messages.businessAddress} values={business} />
            </Link> */}

            <br />
            <br />

            <div css={siteLinksCss}>
              <SiteLinks />
            </div>

            {/* <FormattedMessage {...messages.businessVatId} values={business} />

            <br />

            <FormattedMessage {...messages.businessTaxId} values={business} /> */}

            {/* <br />
            <br /> */}

            <div css={copyrightCss}>
              {site.copyrightHolder}
              {' © '}
              {new Date().getFullYear()}
            </div>
          </div>
        </div>

        <div css={[footerColumnCss, footerMenuCss, secondFoorterColumnCss]}>
          {Object.entries<string>(social.links).map(([provider, url]) => {
            if (provider === 'facebook') {
              return (
                <Link
                  css={footerLinkCss}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faFacebook} fixedWidth />{' '}
                  <FormattedMessage {...messages.facebook} />
                </Link>
              );
            }

            if (provider === 'linkedin') {
              let linkedUrl = url;

              if (locale === 'it') {
                linkedUrl += '?locale=it_IT';
              }

              return (
                <Link
                  css={footerLinkCss}
                  key={provider}
                  href={linkedUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faLinkedin} fixedWidth />{' '}
                  <FormattedMessage {...messages.linkedin} />
                </Link>
              );
            }

            if (provider === 'whatsappSafe') {
              return (
                <SafeText key={provider} text={url}>
                  {(text) =>
                    text && (
                      <Link
                        css={footerLinkCss}
                        href={text}
                        target="_blank"
                        rel="noopener"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} fixedWidth />{' '}
                        <FormattedMessage {...messages.whatsapp} />
                      </Link>
                    )
                  }
                </SafeText>
              );
            }

            if (provider === 'telegram') {
              return (
                <Link
                  css={footerLinkCss}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faTelegram} fixedWidth />{' '}
                  <FormattedMessage {...messages.telegram} />
                </Link>
              );
            }

            if (provider === 'github') {
              return (
                <Link
                  css={footerLinkCss}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faGithub} fixedWidth />{' '}
                  <FormattedMessage {...messages.github} />
                </Link>
              );
            }

            if (provider === 'gitlab') {
              return (
                <Link
                  css={footerLinkCss}
                  key={provider}
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faGitlab} fixedWidth />{' '}
                  <FormattedMessage {...messages.gitlab} />
                </Link>
              );
            }

            return null;
          })}
        </div>

        <div css={[footerColumnCss, footerMenuCss, thirdFooterColumnCss]}>
          {links.map((link: any, index: number) => {
            const { label, path } = link;

            return (
              <Link key={path} href={path} css={footerLinkCss}>
                {label}
              </Link>
            );
          })}
        </div>
      </Container>
    </footer>
  );
};

Footer.whyDidYouRender = true;

const query = graphql`
  query {
    site: settingsYaml(settings: { eq: "site" }) {
      title
      copyrightHolder
    }

    business: settingsYaml(settings: { eq: "business" }) {
      taxId
      vatId
      address
      city
      state
      postalCode
      country
      phoneSafe
      emailSafe

      geoPosition {
        lon
        lat
      }
    }

    social: settingsYaml(settings: { eq: "social" }) {
      links {
        facebook
        linkedin
        github
        gitlab
        whatsappSafe
        telegram
      }
    }

    footerMenus: allNavigationYaml(
      filter: { navigation: { eq: "footer-menu" } }
    ) {
      nodes {
        locale

        links {
          path
          label
        }
      }
    }

    profilePicture: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "profile-picture-round.png" }
    ) {
      childImageSharp {
        original {
          src
        }

        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
